import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/projects/:projectId/playground',
    name: 'Playground',

    components: {
      default: () => import('@/modules/Playground/views/Playground.vue'),
    },
  },
];
export default routes;
