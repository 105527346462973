import type { RouteRecordRaw } from 'vue-router';

export const TRACE_DEFAULT_ROUTE_NAME = 'Traces';

const routes: RouteRecordRaw[] = [
  {
    path: '/projects/:projectId/traces',
    name: TRACE_DEFAULT_ROUTE_NAME,
    components: {
      default: () => import('@/modules/Traces/views/ListTraces.vue'),
    },
  },
  {
    path: '/projects/:projectId/traces/:traceId',
    name: 'Trace',
    components: {
      default: () => import('@/modules/Traces/views/ViewTrace.vue'),
      sidepanel: () => import('@/modules/Traces/components/SpanDetails/SpanDetails.vue'),
    },
  },
];

export default routes;
